<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ pageName }} Apotek</strong>
              </h5>
            </template>
            <b-alert dismissible fade :show="showing" :variant="variant">
              {{ msg }}
            </b-alert>
            <b-row>
              <b-col>
                <b-form-group label="Ruangan" label-for="ruangan-asal">
                  <Multiselect 
                    id="ruangan-asal"
                    v-model="data_filter.ruangan_asal"
                    :options="option_poli"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nama_poli"
                    track-by="ms_poli_id"
                    placeholder="-- Ruangan --"
                    size="sm"
                  ></Multiselect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Status Resep" label-for="status-obat">
                  <Multiselect
                    id="status-obat"
                    v-model="data_filter.status_resep"
                    :options="option_status_resep"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nama_status"
                    track-by="status_id"
                    placeholder="-- Status Resep --"
                    size="sm"
                  ></Multiselect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Tanggal Kunjungan" label-for="tanggal_kunjungan">
                  <DatePicker
                    style="width: 100%"
                    id="tanggal_kunjungan"
                    format="DD-MM-YYYY"
                    v-model="data_filter.tanggal_kunjungan"
                  >
                  </DatePicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row align-h="end">
              <b-col cols="auto">
                <b-button variant="light" @click="resetFilter()">Reset</b-button>
              </b-col>
              <b-col cols="auto">
                <b-button variant="primary" @click="getSearch()">Cari</b-button>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                  :tbody-tr-class="rowClass"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail Data'"
                      @click="openDetailResep(item.item)"
                    >
                      <CIcon name="cil-info" />
                    </b-button>

                    <!-- <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete
                      @click="is_data = item.item"
                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                    > -->
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6" offset-md="3">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-row class="mb-1">
              <b-col cols="12">
                <small>KETERANGAN</small>
              </b-col>
              <b-col cols="auto" style="display: flex; align-items: center">
                <div class="layout-keterangan belum"></div>
                <div class="ml-1">Obat Belum Diberikan</div>
              </b-col>
              <b-col cols="auto" style="display: flex; align-items: center">
                <div class="layout-keterangan sedang"></div>
                <div class="ml-1">Obat Sedang Disiapkan</div>
              </b-col>
              <b-col cols="auto" style="display: flex; align-items: center">
                <div class="layout-keterangan sudah"></div>
                <div class="ml-1">Obat Sudah Diberikan</div>
              </b-col>
              <b-col cols="auto" style="display: flex; align-items: center">
                <div class="layout-keterangan tidak"></div>
                <div class="ml-1">Obat Tidak Diambil</div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- <modal-input
      :fileName="file_name"
      :option_role="option_role"
      :option_poli="option_poli"
      @alertFromChild="triggerAlert($event), getDatas()"
    /> -->
    <modal-edit
      :fileName="file_name"
      :data_edit="is_data"
      :option_role="option_role"
      :option_poli="option_poli"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <!-- <modal-delete
      :fileName="file_name"
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    /> -->
  </div>
</template>

<script>
// import ModalDelete from "./modalDelete.vue";
// import ModalInput from "./modalInput.vue";
import Multiselect from "vue-multiselect";
import ModalEdit from "./modalEdit.vue";

export default {
  name: "resep",
  components: {
    // ModalInput,
    // ModalDelete,
    ModalEdit,
    Multiselect,
  },
  data() {
    return {
      file_name: "resep",
      showing: false,
      variant: "success",
      msg: "",
      data_filter: {
        ruangan_asal: "",
        ruangan_tujuan: "",
        status_resep: "",
        tanggal_kunjungan: new Date(),
      },
      is_data: {},
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center col-1",
        },
        {
          key: "status_prioritas",
          label: "Prioritas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? "Ya" : "Tidak";
          },
        },
        {
          key: "no_antrian",
          label: "No. Antrian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggal_daftar",
          label: "Tanggal Resep",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? this.$moment(val).format("DD-MM-YYYY") : "-";
          },
        },
        {
          key: "nama_pasien",
          label: "Pasien",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_rm",
          label: "RM",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli",
          label: "Ruangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_dokter",
          label: "Dokter",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_perawat",
          label: "Perawat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_apoteker",
          label: "Apoteker",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_asisten_apoteker",
          label: "Asisten Apoteker",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
      option_poli: [],
      option_status_resep: [
        { status_id: 0, value: 0, nama_status: "Tidak Diambil" },
        { status_id: 1, value: 1, nama_status: "Belum Diberikan" },
        { status_id: 2, value: 2, nama_status: "Sedang Disiapkan" },
        { status_id: 3, value: 3, nama_status: "Sudah Diberikan" },
      ],
      option_role: [
        { value: "admin", text: "admin" },
        { value: "gudang", text: "gudang" },
        { value: "kasir", text: "kasir" },
        { value: "dokter", text: "dokter" },
        { value: "bidan", text: "bidan" },
        { value: "perawat", text: "perawat" },
        { value: "sanitarian", text: "sanitarian" },
        { value: "nutrisionis", text: "nutrisionis" },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.is_data.tanggal_kunjungan = new Date();
    this.getDatas();
    this.getSearch();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this;
      vm.tableBusy = true;
      // poli
      let option_poli = await vm.$axios.post("/ms_poli/list");
      vm.option_poli = option_poli.data.status == 200 ? option_poli.data.data.map((x) => {
        return { 
          ...x, 
          value: x.ms_poli_id, 
          text: x.nama_poli 
        };
      }): [];
    },
    openDetailResep(val) {
      // this.$router.push({ path: "/apotek/detail_resep/" + val.kunjungan_id });
      this.$router.push({ path: "/apotek/detail_resep/" + val.resep_id });
    },
    async getSearch() {
      const vm = this;
      vm.tableBusy = true;
      console.log(vm.search);
      try {
        let res = await vm.$axios.post(`/${vm.file_name}/list`, {
          ...vm.data_filter,
          ms_poli_id: vm.data_filter.ruangan_asal ? vm.data_filter.ruangan_asal.ms_poli_id : undefined, 
          status_resep: vm.data_filter.status_resep ? vm.data_filter.status_resep.value : undefined, 
          tanggal_kunjungan: vm.data_filter.tanggal_kunjungan ? vm.$moment(vm.data_filter.tanggal_kunjungan).format('YYYY-MM-DD') : undefined, 
        });
        if (res.data.status == 200 && res.data.message == 'sukses') {
          vm.items = res.data.data;
          for (let i = 0; i < vm.items.length; i++) {
            let x = vm.items[i];
            x.nama_pasien = x.nama_lengkap;
          }
          vm.totalRows = vm.items.length;
          vm.tableBusy = false;
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
        vm.getDatas()
      }
    },
    resetFilter() {
      this.data_filter = {
        ruangan_asal: "",
        ruangan_tujuan: "",
        status_resep: "",
        tanggal_kunjungan: new Date(),
      }
      this.getSearch()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status_resep == 2) {
        return "table-warning";
      }
      if (item.status_resep == 3) {
        return "table-success";
      }
      if (item.status_resep == 0) {
        return "table-danger";
      }
    },
  },
};
</script>

<style scoped>
.layout-keterangan {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 5px 8px 4px 8px;
  align-items: center;
  gap: 4px;
}
.belum {
  border-radius: 8px;
  border: 1px solid var(--color-greyscale-200, #d3d3d3);
  background: var(--color-greyscale-0, #fff);
}
.sedang {
  border-radius: 8px;
  border: 1px solid var(--color-greyscale-200, #d3d3d3);
  background: rgba(242, 153, 74, 0.5);
}
.sudah {
  border-radius: 8px;
  border: 1px solid var(--color-greyscale-200, #d3d3d3);
  background: rgba(33, 150, 83, 0.5);
}
.tidak {
  border-radius: 8px;
  border: 1px solid var(--color-greyscale-200, #d3d3d3);
  background: rgba(235, 87, 87, 0.5);
}
</style>
